import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker } from 'rsuite';
import { CoreButton, CoreDatePicker, CoreInputBox, CoreTextArea, Select2DropDown, TagSelectPicker } from 'whealth-core-web/components';
import { dateFormatStringSingleDate, splitArrData, splitArrObjData, titlizeString } from 'whealth-core-web/components/Helper';
import inputFields from '../inputFields.json';
import useApiManager from 'networking/ApiManager';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';
import roles from './DroDepartment.json';
function QuestionsList(props) {
  let { data, inputDataObj, setinputDataObj, errors, hancleSave } = props;
  let subSourceLabel = inputFields?.subSourceQuestion?.label;
  let subSourceKeysObj = inputFields?.subSourceQuestion?.keys || {};
  let pastHistoryKeysObj = inputFields?.pastTreatmentHistory || {};
  let surgeonKeysObj = inputFields?.surgeon || {};
  let userData = useGetLoginUserDataQuery()?.data?.data || {};
  const ApiClient = useApiManager();
  const patientReferredVar ='patient_referred';

  let subSourceKeys = Object.keys(subSourceKeysObj).map((key) => {
    return subSourceKeysObj[key];
  });
  let pastHistoryKeys = Object.keys(pastHistoryKeysObj)
    .map((key) => {
      return pastHistoryKeysObj[key];
    })
    .join(',');
  let surgeonKeys = Object.keys(surgeonKeysObj)
    .map((key) => {
      return surgeonKeysObj[key];
    })
    .join(',');

  const [hideSubSourceKeys, setHideSubSourceKeys] = useState(subSourceKeys);
  const [hideInsurenceeKeys, sethideInsurenceeKeys] = useState('');

  const [hidePastHistoryKeys, setHidePastHistoryKeys] = useState(pastHistoryKeys.split(','));
  const [hideSurgeonKeys, setHideSurgeonKeys] = useState(surgeonKeys.split(','));

  const [selectedSource, setSelectedSource] = useState('');
  const [selectedPastTreatment, setSelectedPastTreatment] = useState([]);
  const [selectedSurgeon, setSelectedSurgeon] = useState([]);
  const [isRemove, setIsRemove] = useState(false);
  const [feet, setFeet] = useState('');
  const [inch, setInch] = useState('');
  const [insuranceTypeOptions, setInsuranceTypeOptions] = useState('');
  const [patientReferredList, setPatientReferredList] = useState([]);

  useEffect(() => {
    let insuranceCatQuestionIndex = inputDataObj.findIndex((item2) => item2.key == 'insurance_category');
    let insuranceCatQuestion = inputDataObj[insuranceCatQuestionIndex];
    if (insuranceCatQuestion?.answer?.includes('Private insurance') && insuranceCatQuestion?.answer?.includes('Govt insurance')) {
      setInsuranceTypeOptions('Corporate|Retail|CGHS|DGHS|DJB|ECHS|ESIC|Ayushman|MCD');
    } else if (insuranceCatQuestion?.answer?.includes('Govt insurance')) {
      setInsuranceTypeOptions('CGHS|DGHS|DJB|ECHS|ESIC|Ayushman|MCD');
    } else if (insuranceCatQuestion?.answer?.includes('Private insurance')) {
      setInsuranceTypeOptions('Corporate|Retail');
    } else {
      setInsuranceTypeOptions('');
    }
    if (patientReferredList.length == 0) {
      handlePatientSearch('');
    }
  }, [inputDataObj]);

  

  const handlePatientSearch = (search_str) => {
    const patientReferred = inputDataObj.findIndex((item) => item.key == 'patient_referred');
    if (patientReferred !== -1)
    {
      ApiClient.getSearchPatients(search_str, inputDataObj[patientReferred]?.answer)
        .then((res) => {
          setPatientReferredList(res.data ? res.data : []);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };


  const handleItemSelect = (value, index, isSourceDro) => {

    let RetailAnswer = inputDataObj.filter((item) => item.key == 'insurance_tpa');
    if (value == 'Corporate' || value == 'Retail') {
      RetailAnswer[0].answer = '';
    }
    let newObj = { ...inputDataObj[index] };
    newObj.answer = value;
    inputDataObj[index] = newObj;
    if (isSourceDro) {
      const indexOfDepartmentSubsource = inputDataObj.findIndex((obj) => obj.key === 'department_subsource');
      let newObjOfDep = { ...inputDataObj[indexOfDepartmentSubsource] };
      if (roles[value]) {
        newObjOfDep.sub_options = roles[value];
        inputDataObj[indexOfDepartmentSubsource] = newObjOfDep;
      }
    }
    setinputDataObj([...inputDataObj]);
  };

  useEffect(() => {
    let filterHeightQuestion = data.find((item) => item?.key === 'height');
    if (filterHeightQuestion?.answer) {
      let heightInCentimeters = parseFloat(filterHeightQuestion.answer);
      let convertedHeight = convertCentimeterToFeetAndInch(heightInCentimeters);
      setFeet(convertedHeight.feet.toString());
      setInch(convertedHeight.inch.toString());
    }
  }, [data]);

  const convertCentimeterToFeetAndInch = (centimeter) => {
    const feet = Math.floor(centimeter / 30.48);
    const remainingCentimeter = centimeter % 30.48;
    const inch = Math.round(remainingCentimeter / 2.54);
    return { feet, inch };
  };

  const removeAnswer = (keys = []) => {
    if (keys?.length) {
      setinputDataObj((prev) => {
        let oldQuestions = isRemove ? prev : data;
        return oldQuestions.map((item) => {
          if (keys?.includes(item.key)) {
            return {
              ...item,
              answer: null,
            };
          }
          return item;
        });
      });
    }
  };

  useEffect(() => {
    let hideQuestionKeys = subSourceKeys.filter((e) => e != subSourceKeysObj[selectedSource]);
    setHideSubSourceKeys(hideQuestionKeys);
    removeAnswer(hideQuestionKeys);
  }, [selectedSource]);

  useEffect(() => {
    let objectKeys = selectedPastTreatment?.map((e) => pastHistoryKeysObj[e])?.join(',');
    let hideQuestionKeys = [];
    if (selectedPastTreatment?.length > 0 && objectKeys?.length > 0) {
      hideQuestionKeys = pastHistoryKeys?.split(',').filter((e) => !objectKeys?.includes(e));
    } else {
      hideQuestionKeys = pastHistoryKeys?.split(',');
    }
    setHidePastHistoryKeys(hideQuestionKeys);
    removeAnswer(hideQuestionKeys);
    if (!selectedPastTreatment?.includes('Surgeon / Specialist')) {
      setSelectedSurgeon([]);
    }
  }, [selectedPastTreatment]);

  useEffect(() => {
    let objectKeys = selectedSurgeon.map((e) => surgeonKeysObj[e])?.join(',');
    let hideQuestionKeys = [];
    if (selectedSurgeon?.length > 0 && objectKeys?.length > 0) {
      hideQuestionKeys = surgeonKeys?.split(',').filter((e) => !objectKeys?.includes(e));
    } else {
      hideQuestionKeys = surgeonKeys?.split(',');
    }
    setHideSurgeonKeys(hideQuestionKeys);
    removeAnswer(hideQuestionKeys);
  }, [selectedSurgeon]);

  useEffect(() => {
    let filterSourceQuestion = data.find((item) => item?.key === 'source');
    if (filterSourceQuestion?.answer) {
      setSelectedSource(filterSourceQuestion?.answer);
    }
    let filterPastTreatmentQuestion = data.find((item) => item?.key === 'past_treatment');
    if (filterPastTreatmentQuestion?.answer) {
      setSelectedPastTreatment(filterPastTreatmentQuestion?.answer?.split('|'));
    }
    let filterSurgeonQuestion = data.find((item) => item?.key === 'surgeon');
    if (filterSurgeonQuestion?.answer && filterPastTreatmentQuestion?.answer?.includes('Surgeon / Specialist')) {
      setSelectedSurgeon(filterSurgeonQuestion?.answer?.split('|'));
    }
  }, [data]);

  const handleMultiSelect = (value, index) => {
    let removeInsurenceType = data.findIndex((item) => item.key == 'insurance_type');
    if (inputDataObj[index].key == 'insurance_category') {
      inputDataObj[removeInsurenceType].answer = null;
    }
    if (value) {
      value = value.join('|');
      let newObj = { ...inputDataObj[index] };
      newObj.answer = value;
      inputDataObj[index] = newObj;
      setinputDataObj([...inputDataObj]);
    }
  };

  const searchSubSource = (query, index) => {
    let filterSubSourceQuestion = inputDataObj[index].options;
    if (query?.trim().length > 2 && !filterSubSourceQuestion?.toLowerCase()?.includes(query.toLowerCase())) {
      let addSearchSubSource = {
        title: `Add New : ${query}`,
        iscreate: true,
      };
      let newObj = { ...inputDataObj[index] };
      newObj.searchData = addSearchSubSource;
      inputDataObj[index] = newObj;
      setinputDataObj([...inputDataObj]);
    } else {
      let newObj = { ...inputDataObj[index] };
      delete newObj.searchData;
      inputDataObj[index] = newObj;
      setinputDataObj([...inputDataObj]);
    }
  };


  const createOptions = (index) => {
    let newOption = inputDataObj[index].searchData;
    let newOptionName = newOption?.title?.slice(10, newOption.title.length);
    let apiData = { profile_question_id: inputDataObj[index].profile_question_id, option: newOptionName };

    ApiClient.createSubSourceOption(apiData)
      .then((res) => {
        let newObj = { ...inputDataObj[index] };
        delete newObj.searchData;
        newObj.options = newObj.options + '|' + newOptionName;
        newObj.answer = newOptionName;
        inputDataObj[index] = newObj;
        setinputDataObj([...inputDataObj]);
      })
      .catch((err) => {
        searchSubSource('', index);
      });
  };
  const customPlaceholder = (key) => {
    if (key === 'department_subsource') {
      return 'Hospital';
    } else if (key === 'dro_name') {
      return 'DRO Name';
    } else if (key === 'insurance_tpa') {
      return 'Insurance TPA';
    } else {
      return titlizeString(key)[0].toUpperCase() + titlizeString(key).substring(1);
    }
  };
  const renderSelectOptions = (item, index1) => {
    let type = item.type;
    let { options, question, key, sub_options } = item;

    if (key == 'insurance_type' && !insuranceTypeOptions?.length) {
      return null;
    }
    let arrData = splitArrObjData(key == 'insurance_type' ? insuranceTypeOptions : sub_options ? sub_options : options, '|', 'title');
    if (subSourceKeys?.includes(item.key) && item?.key!==patientReferredVar) {
      question = subSourceLabel;
      key = subSourceLabel;
      if (inputDataObj[index1] && inputDataObj[index1]?.searchData?.title?.includes('Add New : ')) {
        arrData = [inputDataObj[index1]?.searchData];
      } else {
        arrData = splitArrObjData(inputDataObj[index1]?.options, '|', 'title');
      }
    }
    var patientRefeered = inputDataObj.find((item) => item.key == 'organic');
    if (item && type == 'single_select') {
      if (selectedSource != 'DRO' && (item?.key === 'dro_name' || item?.key === 'department_subsource')) return null;
      if (selectedSource != 'organic' && item?.key === 'patient_referred' && patientRefeered?.answer !== 'Referred By Patient') return null;
      if (item?.key === 'department_subsource' && item?.sub_options === 'private_clinic_option') {
        return null;
      }
      const isPatientReferred = item?.key === 'patient_referred';
      return (
        <div className='mb-3 capitalize'>
          <Select2DropDown
            placement='auto'
            retuired={item?.is_mandatory || titlizeString(question) === 'Sub Source'}
            showMSG={errors[index1]?.answer}
            label={titlizeString(question)}
            data={isPatientReferred ? patientReferredList : arrData}
            style={{ width: '100%' }}
            labelKey={isPatientReferred ? 'full_name' : 'title'}
            valueKey={isPatientReferred ? 'id' : 'value'}
            placeholder={customPlaceholder(key)}
            defaultValue={isPatientReferred ? parseInt(inputDataObj[index1]?.answer ): inputDataObj[index1]?.answer?.toLowerCase() || 'Walkin'}
            onSearch={(str) => {
              if (subSourceKeys?.includes(item.key) && item?.key != 'ee_lead' && item?.key != 'doctor_ref') {
                searchSubSource(str, index1);
              } else if (item?.key == 'patient_referred' && str.length > 2) {
                handlePatientSearch(str);
              }else {
                return null;
              }
            }}
            isValidation
            onSelect={(val, dataObj) => {
              if (isPatientReferred) {
                handleItemSelect(val, index1);
              } else {
                sethideInsurenceeKeys(dataObj.value);
                setIsRemove(true);
                if (dataObj?.iscreate) {
                  createOptions(index1);
                  return;
                }
                if (item.key == 'source') {
                  setSelectedSource(dataObj.title);
                }
                handleItemSelect(dataObj.title, index1, titlizeString(question) === 'Sub Source' && selectedSource === 'DRO');
              }
            }}
            msgStyle={{ color: 'red' }}
            value={isPatientReferred ? parseInt(inputDataObj[index1]?.answer) : inputDataObj[index1]?.answer?.toLowerCase() || 'Walkin'}
          />
        </div>
      );
    } else if (type && type == 'multi_select') {
      let activeData = splitArrData(inputDataObj[index1]?.answer?.toLowerCase(), '|');
      return (
        <div className='mb-3 capitalize'>
          <TagSelectPicker
            showMSG={errors[index1]?.answer}
            label={titlizeString(question)}
            data={arrData}
            style={{ width: '100%' }}
            labelKey='title'
            valueKey='value'
            placeholder={titlizeString(key)}
            defaultValue={activeData}
            onSelect={(valArray, titleArray) => {
              setIsRemove(true);
              if (item.key == 'past_treatment') {
                setSelectedPastTreatment(titleArray);
              } else if (item.key == 'surgeon') {
                setSelectedSurgeon(titleArray);
              }
              handleMultiSelect(titleArray, index1);
            }}
            msgStyle={{ color: 'red' }}
            value={activeData}
          />
        </div>
      );
    } else {
      return;
    }
  };
  const heightUnitData = {
    feetData: [
      {
        title: '0 Ft',
        id: '0',
      },
      {
        title: '1 Ft',
        id: '1',
      },
      {
        title: '2 Ft',
        id: '2',
      },
      {
        title: '3 Ft',
        id: '3',
      },
      {
        title: '4 Ft',
        id: '4',
      },
      {
        title: '5 Ft',
        id: '5',
      },
      {
        title: '6 Ft',
        id: '6',
      },
      {
        title: '7 Ft',
        id: '7',
      },
      {
        title: '8 Ft',
        id: '8',
      },
      {
        title: '9 Ft',
        id: '9',
      },
    ],
    inchData: [
      {
        title: '0 In',
        id: '0',
      },
      {
        title: '1 In',
        id: '1',
      },
      {
        title: '2 In',
        id: '2',
      },
      {
        title: '3 In',
        id: '3',
      },
      {
        title: '4 In',
        id: '4',
      },
      {
        title: '5 In',
        id: '5',
      },
      {
        title: '6 In',
        id: '6',
      },
      {
        title: '7 In',
        id: '7',
      },
      {
        title: '8 In',
        id: '8',
      },
      {
        title: '9 In',
        id: '9',
      },
      {
        title: '10 In',
        id: '10',
      },
      {
        title: '11 In',
        id: '11',
      },
    ],
  };

  const convertFeetAndInchToCentimeter = (feet, inch) => {
    const feetInCm = feet * 30.48; // 1 foot = 30.48 centimeters
    const inchInCm = inch * 2.54; // 1 inch = 2.54 centimeters
    return feetInCm + inchInCm;
  };

  const renderQuestions = (item, index) => {
    let RetailAnswer = inputDataObj.filter((item) => item.key == 'insurance_type');
    if (hideSubSourceKeys?.includes(item.key) || hidePastHistoryKeys?.includes(item.key) || hideSurgeonKeys?.includes(item.key)) {
      return;
    }
    if (item.key == 'insurance_tpa' && RetailAnswer[0]?.answer !== 'Retail' && RetailAnswer[0]?.answer !== 'Corporate') {
      return;
    }
    let unitInputstyle = item.unit ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {};
    let heightunitStyle = item.key == 'height' ? { borderRight: 'none', borderRadius: '10px 0 0 10px' } : {};
    const today = new Date().toISOString().substr(0, 10);
    if (item.type == 'text') {
      return (
        <div key={index} className='mb-3 capitalize w-100'>
          <CoreTextArea
            retuired={item?.is_mandatory}
            inputStyle={{ height: '36px' }}
            showMSG={errors[index]?.answer}
            setValue={(data) => {
              let questionObj = { ...item, answer: data };
              inputDataObj[index] = questionObj;
              setinputDataObj([...inputDataObj]);
            }}
            value={inputDataObj[index]?.answer}
            label={titlizeString(item.question)}
            placeholder={titlizeString(item.key)}
            rows={2}
          />
        </div>
      );
    } else if (item.type == 'date_time') {
      return (
        <div key={index} className='mb-3 capitalize w-100'>
          <div>
            <label class='inputlabel'>
              {item?.is_mandatory && <span className='requiredInput'> * </span>}
              {titlizeString(item.question)}
            </label>
          </div>
          <div className='w-100'>
            <DatePicker
              disabled={item.key == 'next_followup_date' && item.answer}
              // disabledDate={(date) => {
              //   if (new Date(date) < new Date().setHours(0, 0, 0) && userData?.role != 'super_admin') {
              //     return true;
              //   } else {
              //     return false;
              //   }
              // }}
              format='yyyy-MM-dd HH:mm'
              placeholder='YYYY-MM-DD HH:MM'
              defaultValue={item.answer ? new Date(item.answer) : null}
              onChange={(data) => {
                let questionObj = { ...item, answer: data };
                inputDataObj[index] = questionObj;
                setinputDataObj([...inputDataObj]);
              }}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      );
    } else if (item.type == 'multi_select' || item.type == 'single_select') {
      return <div>{renderSelectOptions(item, index)}</div>;
    } else {
      if (item.type == 'date') {
        let date = new Date(inputDataObj[index]?.answer);

        if (date == 'Invalid Date' || !inputDataObj[index]?.answer) {
          date = null;
        }

        return (
          <div key={index} className='mb-3 capitalize w-100'>
            <CoreDatePicker
              retuired={item?.is_mandatory}
              label={titlizeString(item.question)}
              showMSG={errors[index]?.answer}
              calanderProps={{
                ranges: [
                  {
                    label: 'today',
                    value: new Date(),
                  },
                ],
                value: date,
                onClean: () => {
                  let questionObj = { ...item, answer: null };
                  inputDataObj[index] = questionObj;
                  setinputDataObj([...inputDataObj]);
                },
                preventOverflow: true,
                placeholder: titlizeString(item.key),
                onChange: (data) => {
                  let date = dateFormatStringSingleDate(data);
                  let questionObj = { ...item, answer: date };
                  inputDataObj[index] = questionObj;
                  setinputDataObj([...inputDataObj]);
                },
                placement: 'autoVerticalStart',
                calendarDefaultDate: new Date(),
                onOk: (data) => {
                  let date = dateFormatStringSingleDate(data);
                  let questionObj = { ...item, answer: date };
                  inputDataObj[index] = questionObj;
                  setinputDataObj([...inputDataObj]);
                },
                style: { width: '100%', padding: 0, margin: 0 },
              }}
            />
          </div>
        );
      } else {
        return (
          <div className='d-flex '>
            {item.key !== 'age' && item.key !== 'height' && (
              <div key={index} className='mb-3 capitalize w-100 '>
                <CoreInputBox
                  retuired={item?.is_mandatory}
                  validateZeroToTen={item.key == 'pain'}
                  maxLength={item.key == 'pain' && 2}
                  label={titlizeString(item.question)}
                  disabled={item.key == 'pain' && !item.created_at}
                  showMSG={errors && errors[index]?.answer}
                  inputStyle={{ height: '36px', textTransform: 'capitalize', ...unitInputstyle }}
                  setValue={(data) => {
                    let questionObj = {
                      ...item,
                      answer: data,
                      changes: true
                    };
                    inputDataObj[index] = questionObj;
                    setinputDataObj([...inputDataObj]);
                  }}
                  value={inputDataObj[index]?.answer}
                  aria_expanded={false}
                  validateNumbers={item.type == 'int'}
                  validatedecimal={item.type == 'float'}
                  placeholder={titlizeString(item.key)}
                  min={today}
                />
              </div>
            )}
            {item.unit && item.key !== 'age' && item.key !== 'height' && (
              <div className='unitBox mt-4' style={{ height: '36px' }}>
                {item.unit}
              </div>
            )}

            {item.key === 'height' && (
              <>
                <div className='w-100  mb-3'>
                  <label class='inputlabel'>
                    {item?.is_mandatory && <span className='requiredInput'> * </span>}
                    {titlizeString(item.question)}
                  </label>
                  <div className='d-flex w-100 gap-10'>
                    <div className='specify-time-unit w-100'>
                      <Select2DropDown
                        showMSG={errors && errors[index]?.answer}
                        searchable={false}
                        valueKey='id'
                        labelKey='title'
                        value={feet}
                        onSelect={(data) => {
                          setFeet(data);
                          let questionObj = {
                            ...item,
                            answer: convertFeetAndInchToCentimeter(data, inch) || '',
                          };
                          inputDataObj[index] = questionObj;
                          setinputDataObj([...inputDataObj]);
                        }}
                        data={heightUnitData.feetData}
                        placeholder='Feet'
                        style={{ borderRadius: '8px' }}
                      />
                    </div>
                    <div className='specify-time-unit w-100'>
                      <Select2DropDown
                        showMSG={errors && errors[index]?.answer}
                        searchable={false}
                        valueKey='id'
                        labelKey='title'
                        style={{ borderRadius: '8px' }}
                        value={inch}
                        onSelect={(data) => {
                          setInch(data);
                          let questionObj = {
                            ...item,
                            answer: convertFeetAndInchToCentimeter(feet, data) || '',
                          };
                          inputDataObj[index] = questionObj;
                          setinputDataObj([...inputDataObj]);
                        }}
                        data={heightUnitData.inchData}
                        placeholder='Inch'
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      }
    }
  };

  return (
    <>
      {inputDataObj.map((item, index) => {
        let unitInputstyle = item.unit ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {};
        return <div key={item.key}>{renderQuestions(item, index)}</div>;
      })}
      <button type='submit' className='d-none' />
      {/* <CoreButton onClick={hancleSave} type='submit' title='Save Details' customDivStyle={{ width: '100%' }} /> */}
    </>
  );
}

export default QuestionsList;
